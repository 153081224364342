import moment from 'moment';
import { mapActions } from 'vuex';
import toastMessage from '@/helpers/toastMessage';
import crossHelper from '@/helpers/cross';

export default {
  name: 'CsvMixin',
  methods: {
    ...mapActions('reports-history', { findReport: 'find' }),
    ...mapActions('csv', { createCsv: 'find' }),
    async getCSVFromApi(type, deviceId, asExcel = false, devicesStatus) {
      const reports = await this.findReport({
        query: {
          companyId: this.currentCompany.id,
          userId: this.$store.getters['auth/user'].id,
          type,
          deviceId: deviceId || null,
          $sort: { createdAt: -1 },
          $limit: 1,
        },
      });

      if (reports.data.length > 0 && moment().diff(reports.data[0].createdAt, 'minutes') < 5) {
        toastMessage.showError(`You can request another ${type} report
            ${moment(reports.data[0].createdAt).add(5, 'minutes').fromNow()}`);
      } else {
        if (!deviceId) toastMessage.showSuccess('We will send you an email when the export is ready.');
        const answer = await this.createCsv({
          query: {
            companyId: this.currentCompany.id,
            userId: this.$store.getters['auth/user'].id,
            type,
            asExcel,
            deviceId: deviceId || null,
            devicesStatus,
          },
        });
        if (answer.downloadUrl) crossHelper.openExternalLink(answer.downloadUrl, false);
      }
    },
  },
};
