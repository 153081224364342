<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Export data
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      external-card="material-card-content"
    >
      <div
        slot="body"
      >
        <div>
          <p class="has-margin-top">
            Here you can export consolidated data that Boardgent have about your devices to analyze
            them in other tools like Excel.
          </p>
          <br>
          <b-message
            type="is-success"
            style="margin-bottom: 8px;"
          >
            <p class="center-vertically-action-button">
              <b>Tip:</b>
              You can also export data from only one computer accessing that computer in the
              <span>
                <router-link
                  class="item-dropdown"
                  :to="{ name: 'devices'}"
                >Devices</router-link>
              </span>
              section.
            </p>
          </b-message>
          <br>
          <div
            id="app"
          >
            <div class="columns is-multiline">
              <div
                v-for="(report) of reports"
                :key="report.id"
                :class="reports.length > 1 ? 'column is-half' : 'column is-full'"
              >
                <div class="box">
                  <header>
                    <p class="title is-4">
                      {{ report.name }}
                    </p>
                  </header>
                  <br>
                  <main class="subtitle">
                    {{ report.description }}
                  </main>
                  <footer>
                    <div>
                      <b-dropdown
                        v-if="report.devicesStatus"
                        v-model="report.devicesStatus"
                        aria-role="list"
                      >
                        <template #trigger="{ active }">
                          <b-button
                            :icon-right="active ? 'menu-up' : 'menu-down'"
                            :label="report.devicesStatus.name"
                            type="is-light"
                          />
                        </template>
                        <b-dropdown-item
                          v-for="(option) in devicesToReport"
                          :key="option.value.value"
                          aria-role="listitem"
                          :value="option.value"
                        >
                          {{ option.value.name }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <div>
                      <b-button
                        type="is-light"
                        icon-right="microsoft-excel"
                        class="mr-2"
                        @click="getCSVFromApi(report.id, null, true, report.devicesStatus);
                                addLog(report.id, true)"
                      >
                        Excel
                      </b-button>
                      <b-button
                        type="is-light"
                        icon-right="file-delimited-outline"
                        @click="getCSVFromApi(report.id, null, false, report.devicesStatus);
                                addLog(report.id, false)"
                      >
                        CSV
                      </b-button>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import card from '@/components/cross/Card.vue';

import ErrorMixin from '@/mixins/error';
import CompanyMixin from '@/mixins/company';
import CsvMixin from '@/mixins/csv';
import Roles from '@/mixins/roles';

import vtulEnums from '../../../cross/index';

const { permissions } = vtulEnums.enum.roles;
const { reportsNames, devicesToReport } = vtulEnums.enum.report;
const { planCapabilities } = vtulEnums.enum.planCapabilities;

export default {
  name: 'ExportData',
  metaInfo: {
    title: 'Export data',
  },
  components: {
    card,
  },
  mixins: [ErrorMixin, CompanyMixin, CsvMixin, Roles],
  data() {
    return {
      permissions,
      limitRoles: 10,
      skipRoles: 0,
      total: 0,
      currentPage: this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1,
      devicesToReport,
      allReports: [
        {
          id: reportsNames.BASIC.value,
          name: 'All devices',
          description: 'The overview information of all the devices of your company.',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
        },
        {
          id: reportsNames.SOFTWARE.value,
          name: 'All software',
          description: 'The software installed in all the devices of your company',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
          capabilities: [
            planCapabilities.SOFTWARE_INVENTORY.value,
            planCapabilities.DEVICES_MANAGEMENT.value,
          ],
        },
        {
          id: reportsNames.HARDWARE.value,
          name: 'All hardware',
          description: 'The hardware installed in all the devices of your company',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
          capabilities: [
            planCapabilities.HARDWARE_INVENTORY.value,
            planCapabilities.DEVICES_MANAGEMENT.value,
          ],
        },
        {
          id: reportsNames.DISKS.value,
          name: 'All disks',
          description: 'The disk and partitions of all the devices of your company',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
          capabilities: [
            planCapabilities.SYSTEM_STATUS.value,
            planCapabilities.DEVICES_MANAGEMENT.value,
          ],
        },
        {
          id: reportsNames.BITLOCKER_REQUIREMENTS.value,
          name: 'BitLocker requirements',
          description: 'The compatibility status of your Windows devices with BitLocker',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
          capabilities: [
            planCapabilities.DISK_ENCRYPTION.value,
          ],
        },
        {
          id: reportsNames.BITLOCKER_PASSWORDS.value,
          name: 'BitLocker passwords',
          description: 'The BitLocker passwords and recovery keys of all the devices of your company',
          devicesStatus: devicesToReport.ENABLED_ONLY.value,
          capabilities: [
            planCapabilities.DISK_ENCRYPTION.value,
          ],
          permissions: [
            permissions.SEE_BITLOCKER_PASSWORDS.value.dbValue,
          ],
        },
        {
          id: reportsNames.GEOFENCING.value,
          name: 'All devices outside geofences',
          description: 'The location overview information of all devices that are outside the limits established by the company.',
          capabilities: [
            planCapabilities.GEOFENCING.value,
          ],
          permissions: [
            permissions.GEOFENCING.value.dbValue,
          ],
        },
        {
          id: reportsNames.EXECUTIONS_LOGS.value,
          name: 'Executions logs',
          description: 'All the actions executed over the computers of your company.',
          permissions: [
            permissions.SEE_EXECUTIONS.value.dbValue,
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters('memberships', { findMembershipsInStore: 'find' }),
    memberships() {
      const memberships = this.findMembershipsInStore({
        query: {
          companyId: this.currentCompany.id,
          isOwner: false,
        },
      }).data;

      return memberships;
    },
    reports() {
      const availableReports = this.allReports.filter((x) => (!x.permissions
        || this.validateIfHasPermission(x.permissions))
        && (!x.capabilities || this.validateIfHasCapability(x.capabilities)));
      return availableReports;
    },
  },
  watch: {
    currentPage(newPage) {
      this.$router.replace(`/${this.$route.params.companyId}/reports/${newPage}`);
    },
  },
  created() {
    this.fetchMembershipsFromApi();
  },
  methods: {
    ...mapActions('memberships', { findMemberships: 'find' }),
    ...mapActions('admin-log', { createAdminLog: 'create' }),
    async fetchMembershipsFromApi(skip) {
      if (!this.currentCompany) return;
      let skipMemberships = skip;
      if (!skipMemberships) skipMemberships = 0;

      const { total, data } = await this.findMemberships({
        query: {
          $skip: skipMemberships,
          $limit: 50,
          companyId: this.currentCompany.id,
          isOwner: false,
        },
      });
      this.total = total;
      skipMemberships += data.length;
      if (skipMemberships < total && this.storedDevices.length !== total) {
        await this.fetchMembershipsFromApi(skipMemberships);
      } else {
        this.loading = true;
      }
    },
    changePagination(page) {
      if (page) {
        this.currentPage = page;
      }
      this.skipRoles = (this.currentPage - 1) * this.limitRoles;
    },
    addLog(report, isExcel, includeDevicesStatus = false) {
      const user = this.$store.getters['auth/user'];
      const method = isExcel ? 'Excel' : 'CSV';
      const log = {
        user: user.email,
        action: `All ${report} report exported via ${method}.`,
        companyId: this.currentCompany.id,
        deviceId: this.$route.params.deviceId,
        includeDevicesStatus,
      };
      this.createAdminLog(log);
    },
  },
};
</script>
<style scope>
  .has-margin-top{
    margin-top:0.7rem;
  }
  footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
  }
</style>
